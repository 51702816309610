import { asRouteMap } from '@kaliber/routing'
import { cookieConfiguration } from '/machinery/tracking/cookieConfiguration'

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      v1: {
        path: 'v1',

        cookieConfiguration: {
          path: 'consents',
          data: requestHandlers => requestHandlers.setCookieConfiguration,
        }
      },

      notFound: '*'
    },
    app: {
      path: ':language',
      data: routeData.app.data,
      home: {
        path: '',
      },
      cookiePolicy: {
        path: 'cookie-beleid',
        data: routeData.app.cookiePolicy,
      },
      notFound: '*',
    },
  },
  { trailingSlash: true }
)

function getRouteData() {
  return {
    app: {
      data: {
        fetch: {
          cookieConfiguration: requestHandlers => requestHandlers.getCookieConfiguration
        },
        derived: ({ data, params }) => ({
          title: 'Dat smaakt naar beter',
          showCookieConsent: data?.cookieConfiguration?.configuration.show && data?.doc?._id !== 'cookiePolicy',
        }),
      },

      cookiePolicy: {
        derived: () => ({
          title: 'Cookiebeleid'
        })
      }
    }
  }
}
