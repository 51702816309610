export function cookieConfigurationEvent(configuration) {
  return {
    event: 'cookie_configuration_changed',
    ...cookieConfiguration(configuration).cookieConfiguration
  }
}

export function cookieConfiguration({ permissions }) {
  return {
    cookieConfiguration: {
      ad_storage: 'denied',
      analytics_storage: 'granted',
      fuctionality_storage: 'granted',
      security_storage: 'granted',
      ad_personalization: 'denied',
      personalisation_storage: 'denied',
      ad_user_data: 'denied'
    },
  }
}
